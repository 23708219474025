import React from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'

// Defines the Formspree form for email collection.
const SignupForm = () => {
  return (
    <Form action="https://formspree.io/f/mbjqqlwk" method="POST" style={{maxWidth:"512px", margin:"auto", paddingTop:"16px", paddingBottom:"16px"}}>
      <InputGroup>
          <Form.Control type="email" name="Email Address" placeholder="Email address" />
          <Button variant="accent" style={{color: "#f5efed", backgroundColor:"#957fef"}} type="submit">Submit</Button>
      </InputGroup>
    </Form>
  );
}

export default SignupForm;
