import * as React from 'react'
import BaseLayout from '../components/base-layout'
import SignupForm from '../components/sign-up-form'
import { Col, Row } from 'react-bootstrap'

const IndexPage = () => {
    return (
        <BaseLayout pageTitle="Find the perfect tool to speed up your work | App" pageDescription="Sign in to access a range of simple and secure tools to speed up and automate your everyday work, including merging and splitting spreadsheets, or hashing multiple files.">
            
            <Row>
                <Col style={{textAlign:"center", margin:"32px"}}>
                    <h1 className="display-1" style={{fontWeight:"400"}}><b>Sign up to TNNL</b></h1>
                </Col>
            
            </Row>
            
            <Row>
                <Col>
                    <p style={{textAlign:"center"}} className="lead">
                        <b>
                        With its centralised location and consistent design, micro-tools have never been easier to find, simpler to use or more secure than with TNNL.
                        The TNNL search engine connects you directly to the tool or function you need, so you don't have waste time searching the web for a specific application.
                        </b>
                    </p>
                    <p style={{textAlign:"center"}} className="lead">
                        <b>
                        Sign up for TNNL and be the first to find out about the new tools and functions we're developing.
                        </b>
                    </p>
                </Col>
            
            </Row>
            
            <Row>
                <Col>
                    <SignupForm/>
                </Col>
            
            </Row>
            
            <Row>
                <Col>
                    <p style={{textAlign:"center"}} className="lead">
                        <b>Be sure to follow our <a style={{color:"#957fef"}} href="https://twitter.com/tnnlapp">Twitter</a> and <a style={{color:"#957fef"}} href="https://www.linkedin.com/company/tnnl">LinkedIn</a> pages too!</b>
                    </p>
                </Col>
            </Row>
            
        </BaseLayout>
    )
}

export default IndexPage