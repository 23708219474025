import * as React from 'react'
import Footer from './footer'
import Header from './header'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container } from 'react-bootstrap'

// Defines the basic layout of every page on the website.
// TODO: Change from flexbox div to Container?
const BaseLayout = ({pageTitle, children}) => {
  return (
    <div style={{minHeight:"100vh", height:"100%", display:"flex", flexDirection:"column"}}>
      <Header pageTitle={pageTitle} style={{flex:"0 0 auto"}}></Header>
      <Container fluid="xxl" style={{flex:"1 1 auto", height:"100%", display:"flex", flexDirection:"column", paddingTop:"8px", paddingBottom:"8px"}}>{children}</Container>
      <Footer style={{flex:"0 1 auto"}}/>
    </div>
  )
}

export default BaseLayout